var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _react=_interopRequireDefault(require("react"));
var _Loader=_interopRequireDefault(require("@24i/nxg-sdk-gluons/src/components/ui/Loader"));var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/experience/pages/index.js";

var Index=function Index(){return(
_react.default.createElement(_Loader.default,{
additionalStyles:{
minHeight:'100%',
justifyContent:'center'
},__self:_this,__self:_this,__source:{fileName:_jsxFileName,lineNumber:5,columnNumber:5}}
));};var _default=


Index;exports.default=_default;